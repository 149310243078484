*{
  padding: 0;
  margin: 0;
}

html, body{
  width: 100%;
  height: 100%;
  scrollbar-color: #191e2d #090C16;
  scrollbar-width: thin;
}

body {
  background: #090C16;
  font-family: 'Cabin', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #090C16; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #191e2d; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #282f41;; 
}