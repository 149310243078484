.App {
  width: 100%;
  height: 100%;
  header {
    width: 100%;
    overflow: hidden;
    // background: #12151f
    position: fixed;
    z-index: 1;
    p {
      margin: 16px;
      color: #00FFFF;
      float: left;
      font-size: 16px; }
    a {
      text-decoration: none;
      color: #40424d;
      float: right;
      margin: 16px;
      font-size: 16px;
      &:hover {
        color: #626471; } } }
  .content {
    position: absolute;
    width: 819.19px;
    height: 745.18px;
    top: calc(50% - (745.18px / 2));
    left: calc(50% - (819.19px / 2));
    .fondoCorazon {
      width: 819.185px;
      height: 639px;
      position: absolute;
      left: 0.002px;
      top: 106.182px; }
    .circulos {
      width: 250px;
      height: 250px;
      position: absolute;
      top: 340.962px;
      left: calc(50% - (250px/2));
      .circulo01 {
        width: 250px;
        height: 250px;
        position: absolute;
        background: #2F4366;
        border-radius: 50%;

        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 1;

        animation: scale01 2s ease-in-out infinite; }
      .circulo02 {
        width: 200px;
        height: 200px;
        position: absolute;
        left: calc(50% - (200px/2));
        top: calc(50% - (200px/2));
        background: #2F4366;
        border-radius: 50%;

        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 1;

        animation: scale01 2s ease-in-out 1s infinite; }
      .circulo03 {
        width: 150px;
        height: 150px;
        position: absolute;
        left: calc(50% - (150px/2));
        top: calc(50% - (150px/2));
        background: #2F4366;
        border-radius: 50%;

        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 1;

        animation: scale01 2s ease-in-out 2s infinite; } }
    .monitos {
      width: 819.185px;
      height: 639px;
      position: absolute;
      left: 0.002px;
      top: 106.182px; }
    .pinguino {
      width: 312px;
      height: 271px;
      background: color;
      position: relative;
      left: 46.502px;
      top: 212.682px;
      -ms-transform: translateX(0px);
      transform: translateX(0px);
      transition: 0.3s ease-in-out;
      &:hover {
        -ms-transform: translateX(3px);
        transform: translateX(3px);
        transition: 0.3s ease-in-out; }
      // &:hover ~ .anios
      //   background: #262837
      //   transition: 0.3s ease-in-out
      .pingu {
        width: 312px;
        height: 271px; }
      .pico02 {
        width: 67.207px;
        height: 70.121px;
        left: 242.284px;
        top: 153.092px;
        position: absolute;
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
        -ms-transform-origin: 24.009px 16.24px;
        transform-origin: 24.009px 16.24px;
        transition: 0.3s ease-in-out;

        animation: rotate01 2s ease-in-out infinite; }

      .pico01 {
        width: 77.853px;
        height: 74.848px;
        left: 242.153px;
        top: 146.597px;
        position: absolute;

        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
        -ms-transform-origin: 27.605px 20.903px;
        transform-origin: 27.605px 20.903px;

        animation: rotate01 2s ease-in-out 1s infinite; } }
    .conejo {
      width: 325.24px;
      height: 461.7px;
      left: 457.962px;
      top: 34.574px;
      position: absolute;
      -ms-transform: translateX(0px);
      transform: translateX(0px);
      transition: 0.3s ease-in-out;
      &:hover {
        -ms-transform: translateX(-3px);
        transform: translateX(-3px);
        transition: 0.3s ease-in-out; }
      .oreja02 {
        width: 92.277px;
        height: 215.951px;
        position: absolute;
        left: 130.263px;
        top: 4px;
        -ms-transform-origin: 22.645px 193.502px;
        transform-origin: 22.645px 193.502px;
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);

        animation: rotate01 2s ease-in-out 1s infinite; }

      .oreja01 {
        width: 138.97px;
        height: 198.178px;
        position: absolute;
        left: 176.397px;
        top: 32.734px;
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);

        -ms-transform-origin: 15.288px 176.619px;
        transform-origin: 15.288px 176.619px;

        animation: rotate02 2s ease-in-out 1s infinite; }

      .rabit {
        width: 322.242px;
        height: 305.322px;
        position: absolute;
        left: 1.5px;
        top: 154.932px; } }
    .corazon {
      width: 106.185px;
      height: 95px;
      position: absolute;
      left: 356.502px;
      top: 422.682px;
      cursor: pointer;
      filter: drop-shadow(0px 0px 24px #55ff00);
      -ms-transform: translate(0px) scale(1);
      transform: translateY(0px) scale(1);
      animation: translate01 5s ease-in-out infinite;
      z-index: 2;
      &:hover ~ .linea02 {
        transform: scaleY(1) scaleX(0);
        transition: 0.6s ease-in-out; }
      &:hover ~ .anios {
        background: #00ffd0;
        transition: 0s ease-in-out 0.4s;
        border: solid 4px #73ffe5; } }
    .anios {
      z-index: 2;
      position: absolute;
      left: calc( 50% - 82px );
      width: 160px;
      height: 160px;
      background: #ff3a4e;
      border-radius: 50%;
      margin: 0 auto;
      top: 24px;
      overflow: auto;
      // filter: drop-shadow(0px 0px 24px #55ff00)
      border: solid 4px #ff6070;
      transition: 0s ease-in-out 0.2s;
      p:nth-child(1) {
        margin: 0;
        font-size: 48px;
        color: #090C16;
        text-align: center;
        margin-top: 32px; }
      p:nth-child(2) {
        margin: 0;
        font-size: 24px;
        color: #090C16;
        text-align: center;
        margin-top: -8px; } }
    .linea02 {
      width: 6px;
      height: 400px;
      background: #55ff00;
      position: absolute;
      left: calc(50% - 3px);
      top: 80px;
      transform: scaleY(0) scaleX(1);
      transform-origin: center bottom;
      transition: 0.6s ease-in-out;
      border-radius: 32px; } } }

@keyframes translate01 {
  0%, 100% {
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    filter: drop-shadow(0px 0px 24px #55ff00); }
  50% {
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
    filter: drop-shadow(0px 0px 8px #55ff00); } }

@keyframes scale01 {
  0% {
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 1; }
  100% {
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0; } }

@keyframes rotate01 {
  0%, 100% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -ms-transform: rotate(15deg);
    transform: rotate(15deg); } }

@keyframes rotate02 {
  0%, 100% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg); } }

@keyframes hue01 {
  0%, 100% {
    filter: hue-rotate(0deg); }
  50% {
    filter: hue-rotate(720deg); } }
